import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
  addLearnTime,
  updateLearnTime,
  deleteLearnTime
} from '../../services/learnTime';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '500'
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
    // width: 200,
  }
}));

export default ({ item, onSaveCompleted }) => {
  const classes = useStyles();

  const [changeItem, setChangeItem] = useState(item);

  const save = () => {
    if (changeItem.id) {
      updateLearnTime(changeItem).then(res => {
        onSaveCompleted(res.data);
      });
    } else {
      addLearnTime(changeItem).then(res => {
        onSaveCompleted(res.data);
      });
    }
  };

  const deleteAction = () => {
    deleteLearnTime(changeItem).then(res => {
      onSaveCompleted(null);
    });
  };

  const onChange = event => {
    setChangeItem({ ...changeItem, [event.target.name]: event.target.value });
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {/* <TextField name="standard-basic" label="Standard" fullWidth /> */}

      <TextField
        label="Start"
        type="time"
        name="timeStart"
        value={changeItem.timeStart}
        onChange={onChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: 300 // 5 min
        }}
      />
      <TextField
        label="End"
        type="time"
        name="timeEnd"
        value={changeItem.timeEnd}
        onChange={onChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: 300 // 5 min
        }}
      />
      <TextField
        name="studentName"
        value={changeItem.studentName}
        onChange={onChange}
        label="Student Name"
        fullWidth
      />
      <TextField
        name="remoteUrl"
        value={changeItem.remoteUrl}
        onChange={onChange}
        label="Remote Url"
        fullWidth
      />
      <div>
        <Button variant="contained" color="primary" onClick={save}>
          Save
        </Button>
        <Button
          style={{ float: 'right' }}
          variant="contained"
          color="secondary"
          onClick={deleteAction}
        >
          Delete
        </Button>
      </div>
    </form>
  );
};
