import axios from '../host';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const addLearnTime = item => {
  const body = JSON.stringify(item);
  return axios.post('/learn-time', body, config);
};

export const updateLearnTime = item => {
  const body = JSON.stringify(item);
  return axios.patch(`/learn-time/${item.id}`, body, config);
};

export const deleteLearnTime = item => {
  const body = JSON.stringify(item);
  return axios.delete(`/learn-time/${item.id}`);
};

export const getLearnTime = () => {
  return axios.get('/learn-time');
};
