import React, { useEffect, useState } from 'react';

import { getLearnTime } from '../../services/learnTime';

const Item = ({ top, left, item }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top,
        left,
        width: 300,
        height: 200
      }}
    >
      <a href={item.remoteUrl} target="_blank">
        <div style={{ position: 'absolute', left: 10, top: 20 }}>
          <img src=" images/name.png" alt />
        </div>
        <div style={{ position: 'absolute', left: 0, top: 0 }}>
          <img src="images/time.png" alt />
        </div>
        <div
          style={{
            position: 'absolute',
            left: 133,
            top: 19,
            width: 170,
            height: 29
          }}
        >
          <span style={{ color: '#FFFFFF', fontFamily: 'Arial', fontSize: 24 }}>
            <strong>{item.timeString}</strong>
          </span>
        </div>
        <div
          style={{
            position: 'absolute',
            left: 45,
            top: 60,
            width: 222,
            height: 73,
            textAlign: 'center'
          }}
        >
          <span
            style={{ color: '#000000', fontFamily: 'AngsanaUPC', fontSize: 53 }}
          >
            <strong>{item.studentName}</strong>
          </span>
        </div>
      </a>
    </div>
  );
};

export default () => {
  const [items, setItems] = useState([]);

  const getDataFromHost = () => {
    getLearnTime().then(res => {
      setItems(res.data);
    });
  };

  useEffect(() => {
    getDataFromHost();
  }, []);

  let top = 110;
  let left = 1010;

  const itemJsx = items.map((v, i) => {
    if (i != 0) {
      top += i % 2 == 0 ? 200 : 0;
    }
    return (
      <Item
        top={top}
        left={left + (i % 2 == 1 ? 350 : 0)}
        item={{
          ...v,
          timeString: v.timeStart.substr(0, 5) + ' - ' + v.timeEnd.substr(0, 5)
        }}
      />
    );
  });

  return (
    <>
      <div
        style={{
          position: 'relative',
          left: 0,
          top: 0,
          width: 1920,
          height: 1080
        }}
      >
        <img src="images/background.jpg" alt />
      </div>
      <div
        style={{
          position: 'absolute',
          left: 102,
          top: 0,
          width: 1716,
          height: 1080
        }}
      >
        <img src="images/book_new.png" alt />
      </div>
      <a href="https://zoom.us/support/download" target="_blank">
        <div
          style={{
            position: 'absolute',
            left: 410,
            top: 750,
            width: 373,
            height: 141
          }}
        >
          <img src="images/download.png" id="Image_Zoom" alt />
        </div>
      </a>

      <div
        style={{
          position: 'absolute',
          left: 1610,
          top: 20,
          width: 103,
          height: 107
        }}
      >
        <img
          src="images/refresh.png"
          onClick={getDataFromHost}
          style={{ cursor: 'pointer' }}
          alt
        />
      </div>
      {itemJsx}
    </>
  );
};
