//

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import { getLearnTime } from '../../services/learnTime';
import LearnTimeItem from './edit';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650
//   }
// });

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  //   fab: {
  //     position: 'absolute',
  //     bottom: theme.spacing(2),
  //     right: theme.spacing(2)
  //   },
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
    position: 'relative',
    // minHeight: 200,
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

function getModalStyle() {
  const top = 50; // + rand();
  const left = 50; // + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

export default function Input() {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [itemSelectData, setItemSelectData] = useState(null);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = index => {
    setItemSelectData({ item: items[index], index });
  };

  const handleAddNew = () => {
    setItemSelectData({ item: {}, index: -1 });
  };

  const handleClose = () => {
    setItemSelectData(null);
  };

  const updateItemData = (item, index) => {
    // console.debug('item', item, 'index', index);
    // if (item == null) {
    //   setItems([...items.slice(0, index), ...items.slice(index + 1)]);
    // } else {
    //   const no = index == -1 ? items.length + 1 : index + 1;
    //   const v = {
    //     ...item,
    //     no,
    //     timeString:
    //       item.timeStart.substr(0, 5) + ' - ' + item.timeEnd.substr(0, 5)
    //   };
    //   if (index == -1) {
    //     setItems([...items, v]);
    //   } else {
    //     setItems([...items.slice(0, index), v, ...items.slice(index + 1)]);
    //   }
    // }
    updateItemDataFormHost();
    setItemSelectData(null);
  };

  //   const createData = v => ({
  //     ...v,
  //     timeString: v.timeStart.substr(0, 5) + ' - ' + v.timeEnd.substr(0, 5)
  //   });

  const updateItemDataFormHost = () => {
    getLearnTime().then(res => {
      setItems(
        res.data.map((v, i) => ({
          no: i + 1,
          ...v,
          timeString: v.timeStart.substr(0, 5) + ' - ' + v.timeEnd.substr(0, 5)
        }))
      );
    });
  };

  useEffect(() => {
    updateItemDataFormHost();
  }, []);

  return (
    <Container>
      <div className={classes.root}>
        <TableContainer component={Paper}>
          {/* <Table className={classes.table} aria-label="simple table"> */}
          <Table className={classes.table} aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((row, index) => (
                <TableRow hover key={row.id} onClick={() => handleOpen(index)}>
                  <TableCell>{row.no}</TableCell>
                  <TableCell>{row.timeString}</TableCell>
                  <TableCell>{row.studentName}</TableCell>
                  <TableCell>{row.remoteUrl}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Fab
          aria-label="Add"
          className={classes.fab}
          color="primary"
          onClick={handleAddNew}
        >
          <AddIcon />
        </Fab> */}
        <div style={{ top: 100 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddNew}
            disabled={items.length >= 8}
          >
            Add
          </Button>
        </div>
      </div>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={itemSelectData}
        onClose={handleClose}
      >
        {itemSelectData && (
          <div style={modalStyle} className={classes.paper}>
            <LearnTimeItem
              onSaveCompleted={item =>
                updateItemData(item, itemSelectData.index)
              }
              item={itemSelectData.item}
            />
          </div>
        )}
      </Modal>
    </Container>
  );
}
