import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import './App.css';
import LearnTime from './features/learnTime/LearnTime';
import Input from './features/input/Input';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <LearnTime />
          </Route>
          <Route path="/input">
            <Input />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
