const prod = {
  hostUrl: 'https://online.tuagom.com/online'
};

const dev = {
  hostUrl: 'http://localhost:5001'
};

const config = process.env.NODE_ENV !== 'production' ? dev : prod;
// console.debug('process.env.NODE_ENV', process.env.NODE_ENV);

export default {
  // Add common config values here
  ...config
};
